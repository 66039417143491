import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-regular-svg-icons'
import "../styles/Work.css";


export const Other = () => {
  return (
    <div className="container mt-5">
      <div className="col-md-9 mx-auto">
        <h4 className="text-secondary text-center" data-aos="fade-up" data-aos-duration="1000"><span>Other Noteworthy Projects</span></h4>
        <div className="text-center col-md-12 mb-3">
          <small className="text-secondary">
            <a href="https://github.com/erwinagpasa" target="_blank" rel="noreferrer">
              View the archive
            </a></small>
        </div>

        <div className="row">
          <div className="col-sm-4 g-3">
            <div className="card">
              <div className="card-body">
                <a href="https://production.dkxkliuo07dqn.amplifyapp.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFolder} size="2x" className="text-secondary" />
                </a>

                <h5 className="text-secondary mt-3">
                  <a href="https://production.dkxkliuo07dqn.amplifyapp.com" target="_blank" rel="noreferrer">
                    Ibn Khuldoon National School
                  </a>
                </h5>
                <small className="text-secondary">
                  Is a school in Isa Town, Bahrain. The school is private, coeducational,
                  non-profit, self-supporting, and bilingual.</small>


              </div>
              <div className="card-footer bg-transparent border-0">
                <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                  <li className="list-group-item bg-transparent">ReactJS</li>
                  <li className="list-group-item bg-transparent">Bootstrap</li>
                  <li className="list-group-item bg-transparent">AWS</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-4 g-3">
            <div className="card">
              <div className="card-body">
                <a href="https://ontap.devph.io" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFolder} size="2x" className="text-secondary" />
                </a>

                <h5 className="text-secondary mt-3">
                  <a href="https://ontap.devph.io" target="_blank" rel="noreferrer">
                    ERP System
                  </a>
                </h5>
                <small className="text-secondary">Streamline your business operations with our comprehensive ERP system. Manage your finances, inventory, and customer relationships in one place.</small>


              </div>
              <div className="card-footer bg-transparent border-0">
                <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                  <li className="list-group-item bg-transparent">React</li>
                  <li className="list-group-item bg-transparent">ApolloGraphQL</li>
                  <li className="list-group-item bg-transparent">AWS (CI/CD)</li>
                </ul>
              </div>
            </div>
          </div>


          <div className="col-sm-4 g-3">
            <div className="card">
              <div className="card-body">
                <a href="https://pos.devph.io" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFolder} size="2x" className="text-secondary" />
                </a>

                <h5 className="text-secondary mt-3">
                  <a href="https://pos.devph.io" target="_blank" rel="noreferrer">
                    Point Of Sale App
                  </a>
                </h5>
                <small className="text-secondary">Offers powerful features that enable retailers to easily manage products, prices, and customer data.</small>


              </div>
              <div className="card-footer bg-transparent border-0">
                <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                  <li className="list-group-item bg-transparent">PHP</li>
                  <li className="list-group-item bg-transparent">Javascript</li>
                  <li className="list-group-item bg-transparent">MySQL</li>
                  <li className="list-group-item bg-transparent">Bootstrap</li>
                </ul>
              </div>
            </div>
          </div>


          <div className="col-sm-4 g-3">
            <div className="card">
              <div className="card-body">
                <a href="https://sitemana-com.vercel.app" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFolder} size="2x" className="text-secondary" />
                </a>

                <h5 className="text-secondary mt-3">
                  <a href="https://www.eprexpress.com" target="_blank" rel="noreferrer">Electronic Patient Records</a></h5>
                <small className="text-secondary">Open-source software for clinical application systems and electronic health records.</small>


              </div>
              <div className="card-footer bg-transparent border-0">
                <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                  <li className="list-group-item bg-transparent">NextJS</li>
                  <li className="list-group-item bg-transparent">Apollo GraphQL</li>
                  <li className="list-group-item bg-transparent">MongoDB</li>
                </ul>
              </div>
            </div>
          </div>





          <div className="col-sm-4 g-3">
            <div className="card">
              <div className="card-body">
                <a href="https://nostalgic-goodall-de63e9.netlify.app" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFolder} size="2x" className="text-secondary" />
                </a>

                <h5 className="text-secondary mt-3">
                  <a href="https://nostalgic-goodall-de63e9.netlify.app" target="_blank" rel="noreferrer">
                    Logistics Dashboard
                  </a></h5>
                <small className="text-secondary">
                  Allows for the monitoring on important logistics KPIs concerning warehouse operations, transportation processes and the overall supply chain management.


                </small>

              </div>
              <div className="card-footer bg-transparent border-0">
                <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                  <li className="list-group-item bg-transparent">NextJS</li>
                  <li className="list-group-item bg-transparent">TailwindCSS</li>
                  <li className="list-group-item bg-transparent">Express Server</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-4 g-3">
            <div className="card">
              <div className="card-body">
                <a href="https://angry-thompson-6c2249.netlify.app" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFolder} size="2x" className="text-secondary" />
                </a>

                <h5 className="text-secondary mt-3">
                  <a href="https://angry-thompson-6c2249.netlify.app" target="_blank" rel="noreferrer">Rply.vip</a>
                </h5>
                <small className="text-secondary">Share it with your followers to receive superrelpy.</small>
              </div>
              <div className="card-footer bg-transparent border-0">
                <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                  <li className="list-group-item bg-transparent">ReactJS</li>
                  <li className="list-group-item bg-transparent">Bootstrap</li>
                  <li className="list-group-item bg-transparent">Netlify</li>
                </ul>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  )
}