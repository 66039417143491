import "../styles/Project.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons'
import 'lion-plyr/dist/lion-skin.min.css';
// import { useState, useEffect } from 'react';
// import { UncontrolledLionPlyr, usePlyr, } from 'lion-plyr';

// const onTimeUpdate = (event) => {
//   const instance = event.detail.plyr;
//   console.log(instance.currentTime)
// }

export const Project = () => {

  //   const youtubeRef = usePlyr({
  //   source: {
  //     type: 'video',
  //     sources: [
  //       {
  //         src: 'BbRkmgM3V3k',
  //         provider: 'youtube'
  //       }
  //     ]
  //   }
  // });
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const youtubePlayer = youtubeRef.current?.plyr;

  //   if (youtubePlayer) {
  //     setLoading(false);
  //     youtubePlayer.on('timeupdate', onTimeUpdate);
  //   }

  //   return () => {
  //     if (youtubePlayer) {
  //       youtubePlayer.off('timeupdate', onTimeUpdate)
  //     }
  //   }
  // },[youtubeRef]);

  return (
    <div className="container spacer" id="project">
      <div className="col-md-9 mx-auto">
        <h4 className="text-secondary decorated mb-5" data-aos="fade-up" data-aos-duration="1000"><span>Some Things I've Built</span></h4>


        <div className="portfolio-all mt-5 row" data-aos="fade-up" data-aos-duration="2000">
          <div className="col-lg-7 portfolio-img video-mobile">
            <a href="https://play.google.com/store/apps/details?id=com.erwinagpasa.expensetracker&pcampaignid=web_share" target="_blank" rel="noreferrer">
              <img className="w-100" alt="" src={require('../assets/img/expense.jpg')} />
            </a>

          </div>

          <div className="col-lg-5 portfolio-wrapper-right">
            <small className="d-block text-blue text-start">Personal Project</small>
            <h4 className="text-secondary text-start">Expense Tracker App</h4>
            <div className="description">
              <div className="text-inner">
                <p className="text-secondary">Expense Tracker App makes managing and monitoring your family's expenses effortless. Tailored for busy families, the app helps track every expense, ensuring financial transparency and better habits. Whether planning a family vacation, managing household expenses, or teaching kids about budgeting.
                </p>
              </div>
              <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                <li className="list-group-item bg-transparent">React Native</li>
                <li className="list-group-item bg-transparent">ApolloGraphQL</li>
                <li className="list-group-item bg-transparent">MySQL</li>
                <li className="list-group-item bg-transparent">Tailwind</li>
              </ul>
              <div className="text-start">
                <a href="https://github.com/erwinagpasa" target="_blank" rel="noreferrer" className="me-3"><FontAwesomeIcon icon={faGithub} /></a>
                <a href="https://www.myexpensetracker.app" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFirefoxBrowser} /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 portfolio-img video-desktop">
            <a href="https://play.google.com/store/apps/details?id=com.erwinagpasa.expensetracker&pcampaignid=web_share" target="_blank" rel="noreferrer">
              <img className="w-100" alt="" src={require('../assets/img/expense.jpg')} />
            </a>

            {/* <UncontrolledLionPlyr ref={youtubeRef} isLoading={loading}/>       */}
          </div>
        </div>

        <div className="portfolio-all mt-5 row" data-aos="fade-up" data-aos-duration="2000">
          <div className="col-lg-7 portfolio-img">
            <a href="https://youtu.be/BbRkmgM3V3k" target="_blank" rel="noreferrer">
              <img className="w-100" alt="" src={require('../assets/img/sunmi.jpg')} />
            </a>
          </div>

          <div className="col-lg-5 portfolio-wrapper-left">
            <small className="d-block text-blue text-end">Featured Project</small>
            <h4 className="text-secondary text-end">Parking Reservation App</h4>
            <div className="description">
              <div className="text-inner">
                <p className="text-secondary">Easily pay for street,
                  lot, or garage parking right  from your mobile device.
                  You can also reserve parking ahead of time near
                  garages and stadiums across the country. Receive in-app alerts
                  reminding you of when your
                  parking time or parking coupons are about to expire.</p>
              </div>
              <ul className="list-group list-group-horizontal list-group-flush justify-content-end right-list">
                <li className="list-group-item bg-transparent">Dart (Flutter)</li>
                <li className="list-group-item bg-transparent">GraphQL</li>
                <li className="list-group-item bg-transparent">MongoDB</li>
                <li className="list-group-item bg-transparent">ReactJS (TypeScript)</li>
              </ul>
              <div className="text-end">
                <a href="https://github.com/erwinagpasa" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
              </div>
            </div>
          </div>
        </div>



        <div className="portfolio-all mt-5 row" data-aos="fade-up" data-aos-duration="2000">
          <div className="col-lg-7 portfolio-img video-mobile">
            <a href="https://youtube.com/shorts/VGxdGkS3r-k?feature=share" target="_blank" rel="noreferrer">
              <img className="w-100" alt="" src={require('../assets/img/deliveryapp.jpg')} />
            </a>
          </div>

          <div className="col-lg-5 portfolio-wrapper-right">
            <small className="d-block text-blue text-start">Opensource Project</small>
            <h4 className="text-secondary text-start">Delivery App</h4>
            <div className="description">
              <div className="text-inner">
                <p className="text-secondary">Delivery App is a fast and reliable delivery app designed to streamline order
                  management and real-time tracking. Built with React, Tailwind CSS, and Apollo GraphQL Websocket, it ensures
                  seamless user experiences for customers, couriers, and businesses.
                  The app features secure authentication, order status updates, and real-time delivery tracking,
                  making logistics efficient and hassle-free.</p>
              </div>
              <ul className="list-group list-group-horizontal list-group-flush justify-content-start left-list">
                <li className="list-group-item bg-transparent">React Native</li>
                <li className="list-group-item bg-transparent">Tailwind</li>
                <li className="list-group-item bg-transparent">Apollo GraphQL Websocket</li>

              </ul>
              <div className="text-start">
                <a href="https://github.com/erwinagpasa/nextjs-dashboard-tsx" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 portfolio-img video-desktop">
            <a href="https://youtube.com/shorts/VGxdGkS3r-k?feature=share" target="_blank" rel="noreferrer">
              <img className="w-100" alt="" src={require('../assets/img/deliveryapp.jpg')} />
            </a>
          </div>
        </div>








        <div className="portfolio-all mt-5 row" data-aos="fade-up" data-aos-duration="2000">
          <div className="col-lg-7 portfolio-img">
            <a href="https://velvety-flan-16e644.netlify.app" target="_blank" rel="noreferrer">
              <img className="w-100" alt="" src={require('../assets/img/mob.jpg')} />
            </a>
          </div>
          <div className="col-lg-5 portfolio-wrapper-left">
            <small className="d-block text-blue text-end">Startup Project</small>
            <h4 className="text-secondary text-end">Quest International</h4>
            <div className="description">
              <div className="text-inner">
                <p className="text-secondary">This project is based on how an effective travel app will make us feel.
                  It is based on a traveling app with fully-featured functions that will
                  activate the traveling bug with vibrant imagery.</p>
              </div>
              <ul className="list-group list-group-horizontal list-group-flush justify-content-end right-list">
                <li className="list-group-item bg-transparent">Dart (Flutter)</li>
                <li className="list-group-item bg-transparent">Express Server</li>
                <li className="list-group-item bg-transparent">MongoDB</li>
              </ul>
              <div className="text-end">
                <a href="https://github.com/erwinagpasa/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}