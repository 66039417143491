import React, { useEffect } from 'react';
import { Hero } from "../components/Hero"
import { Navigation } from "../components/Navbar"
import { About } from '../components/About';
import AOS from 'aos';
import "aos/dist/aos.css";
import { Work } from '../components/Work';
import { Project } from '../components/Project';
import { Other } from '../components/Other';
import { Contact } from '../components/Contact';
import { Footer } from '../components/Footer';
import { Brands } from '../components/brands';

export const Homepage = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <div>
      <Navigation />
      <Hero />
      <About />
      <Work />
      <Project />
      <Other />
      <Brands />
      <Contact />
      <Footer />

    </div>
  )
}